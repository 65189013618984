// ! Book_Trial_Field_Filled activity (217)
const bookTrialFieldFilledActivity = (fieldId, fieldTitle, fieldValue) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 217,
    ActivityNote: fieldTitle,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: fieldId,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: fieldTitle,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: fieldValue,
      },
    ],
  };
};
// ! Book_Trial_Submit activity (218)
const bookTrialSubmitActivity = (
  stageId,
  stageTitle,
  dataValidated,
  nextStageId
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 218,
    ActivityNote: stageTitle,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: stageId, // number
      },
      {
        SchemaName: "mx_Custom_2",
        Value: stageTitle, // string
      },
      {
        SchemaName: "mx_Custom_3",
        Value: dataValidated, // string
      },
      {
        SchemaName: "mx_Custom_4",
        Value: nextStageId, // number
      },
    ],
  };
};
// ! Book_Trial_Success activity (219)
const bookTrialSuccessActivity = (
  stageId,
  stageTitle,
  dataValidated,
  nextStageId,
  leadScore,
  childName
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 219,
    ActivityNote: `Booking for ${childName}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: stageId, // number
      },
      {
        SchemaName: "mx_Custom_2",
        Value: stageTitle, // string
      },
      {
        SchemaName: "mx_Custom_3",
        Value: dataValidated, // string
      },
      {
        SchemaName: "mx_Custom_4",
        Value: nextStageId, // number
      },
      {
        SchemaName: "mx_Custom_5",
        Value: leadScore, // number
      },
    ],
  };
};
// ! Add_to_Cart activity (220)
const addToCartActivity = (courseCardId, courseCardName, coursePrice) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 220,
    ActivityNote: courseCardName,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: courseCardId,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: courseCardName,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: coursePrice,
      },
    ],
  };
};
// ! Add_Coupon activity (221)
const addCouponActivity = (couponCode, numberOfItems, cartTotal, currency) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 221,
    ActivityNote: couponCode,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: couponCode,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: numberOfItems,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: cartTotal,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: currency,
      },
    ],
  };
};
// ! Add_Coupon_Success activity (222)
const addCouponSuccessActivity = (
  couponCode,
  numberOfItems,
  status,
  cartTotal,
  finalPrice,
  currency
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 222,
    ActivityNote: status === 0 ? "No" : "Yes",
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: couponCode,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: numberOfItems,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: status,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: cartTotal,
      },
      {
        SchemaName: "mx_Custom_5",
        Value: finalPrice,
      },
      {
        SchemaName: "mx_Custom_6",
        Value: currency,
      },
    ],
  };
};
// ! Invoke_Payment activity (224)
const invokePaymentActivity = (
  orderId,
  totalCartQuantity,
  billingDetailsString,
  totalCartPrice,
  currency,
  cartTotalWithoutDiscount,
  promoCode = ""
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 224,
    ActivityNote: `Total cart price ${totalCartPrice}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: orderId,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: totalCartQuantity,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: billingDetailsString,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: totalCartPrice,
      },
      {
        SchemaName: "mx_Custom_5",
        Value: currency,
      },
      {
        SchemaName: "mx_Custom_6",
        Value: cartTotalWithoutDiscount,
      },
      {
        SchemaName: "mx_Custom_7",
        Value: promoCode,
      },
    ],
  };
};
// ! Payment_Status activity (225)
const paymentStatusActivity = (
  orderId,
  totalCartQuantity,
  status,
  totalCartPrice,
  genus,
  currency,
  cartTotalWithoutDiscount,
  promoCode = ""
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 225,
    ActivityNote: `${totalCartPrice} | Yes`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: orderId, // string
      },
      {
        SchemaName: "mx_Custom_2",
        Value: totalCartQuantity, // number
      },
      {
        SchemaName: "mx_Custom_3",
        Value: status, // number
      },
      {
        SchemaName: "mx_Custom_4",
        Value: totalCartPrice, // string
      },
      {
        SchemaName: "mx_Custom_5",
        Value: genus, // number
      },
      {
        SchemaName: "mx_Custom_6",
        Value: currency, // string
      },
      {
        SchemaName: "mx_Custom_7",
        Value: cartTotalWithoutDiscount, // string
      },
      {
        SchemaName: "mx_Custom_8",
        Value: promoCode, // string
      },
    ],
  };
};
// ! Cart_Click activity (215)
const cartClickActivity = (cartQty, location) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 215,
    ActivityNote: `Total cart qty - ${cartQty}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: cartQty,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: location,
      },
    ],
  };
};
// ! Checkout activity (216)
const checkoutActivity = (
  totalQuantity,
  cartImageStyle,
  locationOfTrigger,
  cartTotal
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 216,
    ActivityNote: `${cartTotal} | ${totalQuantity}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: totalQuantity,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: cartImageStyle,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: locationOfTrigger,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: cartTotal,
      },
    ],
  };
};
// ! Search_Execute activity (214)
const searchExecuteActivity = (
  searchTerm,
  returnedItemCount,
  result,
  searchType
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 214,
    ActivityNote: searchTerm,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: searchTerm,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: returnedItemCount,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: result,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: searchType,
      },
    ],
  };
};
// ! Age_Filter activity (207)
const ageFilterActivity = (groupId, filterRange) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 207,
    ActivityNote: filterRange,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: groupId,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: filterRange,
      },
    ],
  };
};
// ! Category_Filter activity (208)
const categoryFilterActivity = (categoryId, categoryTitle) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 208,
    ActivityNote: categoryTitle,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: categoryId,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: categoryTitle,
      },
    ],
  };
};
// ! Card_Click activity (209)
const cardClickActivity = (
  cardId,
  cardTitle,
  cardType,
  destinationPage,
  age,
  cardFormatId
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 209,
    ActivityNote: `${cardType} | ${cardTitle}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: cardId,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: cardTitle,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: cardType,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: destinationPage,
      },
      {
        SchemaName: "mx_Custom_5",
        Value: age,
      },
      {
        SchemaName: "mx_Custom_6",
        Value: cardFormatId,
      },
    ],
  };
};
// ! Button_Click activity (206)
const buttonClickActivity = (
  buttonId,
  buttonTitle,
  destinationUrl,
  buttonTypeId,
  destinationPageId,
  location
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 206,
    ActivityNote: `${buttonTitle} | ${location}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: buttonId,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: buttonTitle,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: destinationUrl,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: buttonTypeId,
      },
      {
        SchemaName: "mx_Custom_5",
        Value: destinationPageId,
      },
      {
        SchemaName: "mx_Custom_6",
        Value: location,
      },
    ],
  };
};

// ! Main_Menu_Click activity (200)
const mainMenuClickActivity = (
  itemID,
  itemTitle,
  destinationUrl,
  buttonState,
  destinationPageId
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 200,
    ActivityNote: `${itemTitle} | ${window.location.pathname}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: itemID,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: itemTitle,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: destinationUrl,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: buttonState,
      },
      {
        SchemaName: "mx_Custom_5",
        Value: destinationPageId,
      },
    ],
  };
};
// ! Request_Callback activity (204)
const requestCallbackActivity = (
  nameFilled,
  mobileFilled,
  location,
  countryCode,
  phoneNumber,
  courseName
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 204,
    ActivityNote: phoneNumber,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: nameFilled,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: mobileFilled,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: location,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: countryCode,
      },
      {
        SchemaName: "mx_Custom_5",
        Value: courseName,
      },
    ],
  };
};
// ! Footer_Event activity (212)
const footerEventActivity = (buttonTitle) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 212,
    ActivityNote: `clicked on ${buttonTitle}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: buttonTitle,
      },
    ],
  };
};
// ! Book_Trial_Click activity (230)
const bookTrialClickActivity = (
  buttonTitle,
  destinationUrl,
  trialOrWorkshop,
  location,
  clickSource
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 230,
    ActivityNote: `clicked on ${clickSource}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: buttonTitle,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: destinationUrl,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: trialOrWorkshop,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: location,
      },
      {
        SchemaName: "mx_Custom_5",
        Value: clickSource,
      },
    ],
  };
};
// ! Cart_Field_Filled activity (223)
const cartFieldFilledActivity = (fieldId, fieldTitle, fieldValue) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 223,
    ActivityNote: `Filled ${fieldTitle}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: fieldId,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: fieldTitle,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: fieldValue,
      },
    ],
  };
};
// ! Join_Class activity (231)
const joinClassActivity = (
  classId,
  courseId,
  classType,
  classDate,
  sessionNumber,
  courseName,
  opportunityDetails = {},
  teacherName
) => {
  return {
    retry_count: 0,
    is_opportunity: true,
    opportunity_details: opportunityDetails,
    RelatedProspectId: "",
    ActivityEvent: 231,
    ActivityNote: `Joined ${courseName} - ${classType}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: classId, // string
      },
      {
        SchemaName: "mx_Custom_2",
        Value: courseId, // string
      },
      {
        SchemaName: "mx_Custom_3",
        Value: classType, // string
      },
      {
        SchemaName: "mx_Custom_4",
        Value: classDate, // dateTime
      },
      {
        SchemaName: "mx_Custom_5",
        Value: sessionNumber, // number
      },
      {
        SchemaName: "mx_Custom_6",
        Value: teacherName, // string
      },
      // {
      //   SchemaName: "Status",
      //   Value: "Active", // string
      // },
    ],
  };
};
// ! Leave_Class activity (232)
const leaveClassActivity = (
  classId,
  courseId,
  classType,
  classDate,
  sessionNumber,
  courseName
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 232,
    ActivityNote: `Left ${courseName} - ${classType}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: classId,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: courseId,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: classType,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: classDate,
      },
      {
        SchemaName: "mx_Custom_5",
        Value: sessionNumber,
      },
    ],
  };
};
// ! Select_Paid_Batch activity (234)
const selectPaidBatchActivity = (
  batchId,
  courseId,
  countryCode,
  startDate,
  numberOfClasses,
  frequency
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 234,
    ActivityNote: `Selected batch ID ${batchId}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: batchId,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: courseId,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: countryCode,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: startDate,
      },
      {
        SchemaName: "mx_Custom_5",
        Value: numberOfClasses,
      },
      {
        SchemaName: "mx_Custom_6",
        Value: frequency,
      },
    ],
  };
};
// ! Class_Link_Click activity (235)
const classLinkClickActivity = (
  classId,
  courseId,
  classType,
  classLinkType,
  classDate,
  classTiming,
  isActive,
  timeSinceClassStart,
  teacherName
) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 235,
    ActivityNote: `Intended to join class with course ID : ${courseId}`,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: classId,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: courseId,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: classType,
      },
      {
        SchemaName: "mx_Custom_4",
        Value: classLinkType,
      },
      {
        SchemaName: "mx_Custom_5",
        Value: classDate,
      },
      {
        SchemaName: "mx_Custom_6",
        Value: classTiming,
      },
      {
        SchemaName: "mx_Custom_7",
        Value: isActive,
      },
      {
        SchemaName: "mx_Custom_8",
        Value: timeSinceClassStart,
      },
      {
        SchemaName: "mx_Custom_9",
        Value: teacherName,
      },
    ],
  };
};

// !Downlaod_Certificate_activity (257)

const downloadCertificateActivity = (courseId, courseName, batchType) => {
  return {
    retry_count: 0,
    is_opportunity: false,
    RelatedProspectId: "",
    ActivityEvent: 257,
    ActivityNote: courseName,
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: courseId,
      },
      {
        SchemaName: "mx_Custom_2",
        Value: courseName,
      },
      {
        SchemaName: "mx_Custom_3",
        Value: batchType,
      },
    ],
  };
};

export {
  bookTrialSubmitActivity,
  bookTrialSuccessActivity,
  bookTrialFieldFilledActivity,
  addToCartActivity,
  addCouponActivity,
  addCouponSuccessActivity,
  invokePaymentActivity,
  paymentStatusActivity,
  cartClickActivity,
  checkoutActivity,
  searchExecuteActivity,
  ageFilterActivity,
  categoryFilterActivity,
  cardClickActivity,
  buttonClickActivity,
  mainMenuClickActivity,
  requestCallbackActivity,
  footerEventActivity,
  bookTrialClickActivity,
  cartFieldFilledActivity,
  joinClassActivity,
  leaveClassActivity,
  selectPaidBatchActivity,
  classLinkClickActivity,
  downloadCertificateActivity,
};

import axios from "axios";
import _ from "lodash";


const stopEventsActivityEvent=new Set([
  218,217,207,200,230,209,206
])


const leadsquaredActivityDispatcher = (payload, token, id) => {
  // console.log(payload.ActivityEvent,"activityEvent")
 if(stopEventsActivityEvent.has(payload.ActivityEvent)) return;

  const key = 'leadsquaredActivityDispatcherObject'

  let slug = localStorage.getItem(key)
  if (!slug) slug = '[]'

  const historyObject = JSON.parse(slug)

  for (let prev of historyObject) {
    if (_.isEqual(payload, prev.payload)) {
      return
    }
  }

  const currentTime = (new Date()).getTime()

  historyObject.push({
    payload,
    time: currentTime
  })

  // clear past 1 hour entries
  while (historyObject.length !== 0 && currentTime - historyObject[0].time > 1000 * 60 * 60) {
    historyObject.shift()
  }

  if (historyObject.length > 10) historyObject.shift()

  localStorage.setItem(key, JSON.stringify(historyObject))

  // ! Sending activity to LS
  axios
    .post(
      process.env.NEXT_PUBLIC_LEADSQUARED_UPDATE_ACTIVITY_API,
      {
        payload: payload,
      },
      {
        headers: {
          Authorization: token,
          "X-SSUID": id,
        },
      }
    )
    .then(() => { })
    .catch((err) => console.log(err));
};
export { leadsquaredActivityDispatcher };
